@import '../../../css/variables';
@import '../../../css/typography';

.advanced-search-modal {
	box-sizing: border-box;

	.modal-dialog {
		max-width: 940px !important;
	}
}

.advanced-search {
	&-modal-header,
	&-body {
		display: flex;
	}

	&-body {
		flex-direction: row;
		justify-content: space-between;

		&-left {
			border: solid 1px $gray-300;
			border-right: 0;
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
			flex-direction: column;
			width: 100%;
			height: 100%;
			padding: 20px;
		}
	}

	&-learn-more {
		position: absolute;
		bottom: 0;
		display: block;
		margin-bottom: 10px;
	}
	&-image {
		max-width: 100%;
	}
}

.beta-title {
	background: #3db28c;
	padding: 4px 7px;
	color: #fff;
	font-size: 14px;
	border-radius: 3px;
}
.modal-header > .close {
	align-self: end;
}
