@import '../../../css/typography';
@import '../../../css/mixins';
@import '../../../css/variables';

.modal {
	p,
	li,
	table {
		@include font-source(14px, $gray-dark);
	}

	table {
		border: 1px solid #dee2e6;
		margin-bottom: 10px;
		thead {
			th {
				padding: 12px;
				vertical-align: bottom;
				border-bottom: 2px solid #dee2e6;
			}
		}

		td {
			padding: 12px;
			border: 1px solid #dee2e6;
		}
	}
}

.appModal {
	box-sizing: border-box;

	&-header {
		display: flex;
		flex-flow: column nowrap;
		padding: 32px 32px 0 32px;

		&--wrap {
			border-bottom: 1px solid $gray-light;
			padding-bottom: 32px;

			p {
				margin-bottom: 0;
				color: $gray-800;
				font-size: 14px;
			}
		}
	}

	&-head {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		margin-bottom: 10px;

		&--close {
			width: 20px;
			height: 20px;
			fill: $indigo;
			&:hover {
				cursor: pointer;
			}
		}
	}

	&-body {
		padding: 32px 32px 20px 32px;
		height: calc(100vh - 350px);
		overflow-y: auto;

		&--item {
			padding: 16px;
			border-bottom: 1px solid $gray-light;

			h2 {
				margin-bottom: 10px;
			}
		}
	}

	&-non-5safes {
		&-header {
			display: flex;
			flex-flow: column nowrap;
			padding: 22px 22px 0px 22px;

			&--wrap {
				border-bottom: 1px solid $gray-light;

				p {
					margin-bottom: 0;
					color: $gray-800;
					font-size: 14px;
				}
			}
		}
		&-body {
			padding: 22px 22px 0px 22px;
			height: max-content;
			overflow-y: auto;

			&--item {
				padding: 16px;
				border-bottom: 1px solid $gray-light;

				h2 {
					margin-bottom: 10px;
				}
			}
		}
	}

	&-footer {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		align-items: center;
		border-top: 1px solid $gray-300;
		width: 100%;
		height: 72px;
		background: $white;

		&--wrap {
			display: flex;
			flex-flow: row nowrap;
			padding: 0 16px;
		}
	}
}
