@import '../../css/variables';
@import '../../css/typography';
@import '../../css/mixins';

.react-datepicker-wrapper {
	margin-bottom: 4px;
}

.active-group {
	background: #f6f7f8;
}

.active-group:after {
	position: absolute;
	top: 0;
	right: 0;
	content: '';
	height: 100%;
	width: 4px;
	background: $teal;
}

.warning {
	color: #f0bb24;
}

.success {
	color: #3db28c;
}

.danger {
	color: #dc3645;
}

.alert {
	margin-bottom: 8px !important;
	font-size: 14px;
	padding: 12px 1.25rem;

	&-warning {
		border: 2px solid #f0bb24 !important;
	}

	&-success {
		border: 2px solid #3db28c !important;
	}

	&-danger {
		border: 2px solid #dc3645 !important;
		i {
			color: #dc3645;
		}
	}

	&-wrap {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		& > div:first-child {
			margin-right: auto;
		}
		& > div:nth-child(2) {
			cursor: pointer;
			font-weight: bold;
			text-align: right;
		}
	}
}

#darContainer {
	margin-bottom: 100px;
}

.toolTip {
	position: relative;
	display: inline-block;
	padding: 0 5px;
}

.toolTipHidden {
	position: relative;
	display: inline-block;
	padding: 0 5px;
	visibility: hidden;
	opacity: 0;
}

.toolTip .toolTipText {
	visibility: hidden;
	position: absolute;
	width: 120px;
	background-color: #222;
	font-size: 12px;
	color: #fff;
	text-align: center;
	padding: 5px 0;
	border-radius: 6px;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.4s;
}

.toolTipHidden .toolTipText {
	visibility: hidden;
	position: absolute;
	width: 120px;
	background-color: #222;
	font-size: 12px;
	color: #fff;
	text-align: center;
	padding: 5px 0;
	border-radius: 6px;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.4s;
}

.toolTip i:hover + .toolTipText {
	visibility: visible;
	opacity: 1;
}

.toolTipHidden i:hover + .toolTipText {
	visibility: visible;
	opacity: 1;
}

span.toolTipText:after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #222 transparent transparent transparent;
}

.toolTip-top {
	bottom: 125%;
	left: 50%;
	margin-left: -60px;
}

.section-header {
	cursor: pointer;
	margin-left: 26px;
	margin-top: 8px;
	margin-bottom: 16px;
	font-size: 16px;

	display: grid;
	grid-template-columns: 205px 1fr;
	box-sizing: border-box;

	&-active {
		cursor: pointer !important;
		opacity: 1 !important;
		margin-left: 26px;
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 16px;
		font-weight: 700;
		display: grid;
		grid-template-columns: 210px 1fr;
		box-sizing: border-box;
	}
}

.section-not-inreview {
	color: $gray-600 !important;
}

.section-subheader {
	margin-left: 33px;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 12px !important;
	border-left: 1px solid $gray-400;
}

.section-subheader li:first-child {
	margin-top: 8px;
}

.section-subheader li:last-child {
	margin-bottom: 8px;
}

.dar-nav-item {
	display: grid;
	grid-template-columns: 170px 1fr;
	span {
		i {
			font-size: 8px;
		}
	}
	span:nth-child(2) {
		margin-top: -3px;
		margin-left: 3px;
	}
	padding-left: 10px;
}

.dar-nav-item + .dar-nav-item {
	padding-top: 8px;
}

.nav-item-active {
	font-weight: bold !important;
}

.active-border {
	border-left: 4px solid $teal;
	padding-top: 8px;
	padding-bottom: 8px;
}

.active-border > div {
	margin-left: -4px;
}

.active-grey-border {
	border-left: 1px solid $gray-400;
}

.completionIconHolder {
	display: inline-block;
	padding-right: 10px;
}

.titleHolder {
	display: inline-block;
}

.completionIconGap {
	display: inline-block;
	padding-right: 16px;
}

.dar-tabsBackground {
	width: 100%;
	background-color: $white !important;
	align-items: center !important;
	justify-content: center !important;
	margin-left: 1px !important;
}

.banner {
	background-color: $indigo;
	width: 100%;
	min-height: 70px;
	margin-left: 0px !important;
	align-content: center;
}

.banner .banner-left {
	vertical-align: middle;
	padding-left: 32px !important;
}

.banner .banner-right {
	text-align: right;
	vertical-align: middle;
	padding-right: 32px !important;
	svg {
		margin-left: 20px;
		cursor: pointer;
	}
}

#darLeftCol {
	width: calc(25vw - 15px);
	max-height: calc(100vh - 70px);
	height: min-content;
	margin-right: 15px;
	margin-top: 24px;
	padding-top: 24px;
}

#darCenterCol {
	width: calc(41.66vw - 15px);
	margin-left: 15px;
	padding-top: 24px;
}

#darCenterCol.extended {
	width: calc(58.33vw - 15px);
}

#darCenterCol.table {
	width: calc(70.33vw - 15px);
}

#darRightCol {
	max-height: calc(100vh - 79px);
	height: min-content;
	width: calc(33.33vw - 30px);
	padding-top: 24px;
	margin-right: 30px;
}

#darDropdownNav {
	display: none;
}

#darDropdownNav select {
	width: 100%;
	height: 40px;
	background-color: #ffffff;
	border: solid 2px $gray-400;
	border-radius: 4px;
	font-family: museo-sans-rounded;
	font-weight: 500;
	color: $gray-800;
}

.darTab {
	padding: 15px 0 0 0;
	background-color: $white;
	max-height: calc(100vh - 169px);
	margin-left: 1px;
	font-size: 14px;
	header {
		padding: 0 24px 15px 24px;
		display: flex;
		border-bottom: 1px solid rgba(0, 0, 0, 0.09);
		flex-flow: row nowrap;
		align-items: center;
		svg {
			cursor: pointer;
		}
		div {
			display: flex;
			flex-flow: row;
			margin-right: auto;
			align-items: center;
			p {
				margin: 0;
			}
			i {
				font-size: 16px;
				font-weight: 500;
				color: $purple;
			}
		}
	}
	main {
		padding: 24px;
	}
	&-guidance {
		height: 57vh;
		display: flex;
		align-items: center;
		justify-content: center;
		@include font-source(14px, $gray-700, $font-weight-semibold);
	}
	&-messages {
		overflow-y: auto;
		height: 50vh;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		margin-bottom: 4px;
		@include font-source(14px, $gray-700, $font-weight-semibold);
	}
	&-notes {
		background-color: $background;
		overflow-y: auto;
		height: 50vh;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		@include font-source(14px, $gray-700, $font-weight-semibold);
	}
}

@media (min-width: 769px) and (max-width: 1160px) {
	.banner {
		height: 70px !important;
	}

	.darTab {
		max-height: calc(100vh - 208px);
	}
}

@media (min-width: 1161px) {
	#darRightCol {
		max-height: calc(100vh - 79px);
	}

	.darTab {
		max-height: calc(100vh - 169px);
	}
}

@media (max-width: 768px) {
	#darLeftCol,
	#darRightCol {
		display: none !important;
	}

	#darDropdownNav {
		display: block;
		width: 100%;
		margin-bottom: 16px;
	}

	#darCenterCol,
	#darCenterCol.extended,
	#darCenterCol.table {
		width: calc(100vw - 15px);
		margin-right: 15px;
	}
}

@media (min-width: 884px) {
	.banner .banner-right span,
	.banner-right a,
	.banner-right button {
		line-height: 30px;
	}
}

.darIframe {
	height: 600px;
	width: 800px;
}

.ck-button {
	margin: 4px;
	background-color: #efefef;
	border-radius: 4px;
	border: 1px solid #d0d0d0;
	overflow: auto;
	float: left;
}

.ck-button label {
	float: left;
	width: 4em;
}

.ck-button label span {
	text-align: center;
	padding: 3px 0px;
	display: block;
	border-radius: 4px;
}

.ck-button label input {
	position: absolute;
	top: -20px;
}

.ck-button input:hover + span {
	background-color: #efe0e0;
}

.ck-button input:checked + span {
	background-color: #911;
	color: #fff;
}
.ck-button input:checked:hover + span {
	background-color: #c11;
	color: #fff;
}

/* Winterfell Form CSS */

.rbt-input-multi.form-control[disabled] {
	background: #eee !important;
}

.rbt-token-disabled {
	background: #fff !important;
}

input[type='text']:disabled {
	background-color: #eee !important;
}

input[type='text']:disabled,
input[type='checkbox']:disabled,
input[type='radio']:disabled {
	&:after {
		background-color: #eee !important;
	}
}

.dar {
	&__header {
		padding: 24px 25px;

		p {
			margin-bottom: 0;

			&:first-child {
				margin-bottom: 8px !important;
			}
		}
	}

	&__questions {
		margin-bottom: 36px;
		padding: 24px 0;
		margin-top: 1px;
		box-shadow: 0 1px 6px -5px #333;
	}

	&__questions .col-md-12 {
		padding: 0px !important;
		padding-left: 8px !important;
		padding-right: 8px !important;
	}

	&__questions .card-body .form-group {
		padding-left: 0 !important;
		padding-right: 0 !important;
		margin-bottom: 0 !important;
	}

	&__check {
		list-style: none;
		margin-left: 24px;
		label {
			padding: 5px;
			> input[type='checkbox'] {
				margin-right: 10px;
				width: 24px;
				height: 24px;
				cursor: pointer;
				text-align: center;
				vertical-align: middle;
				margin-left: -30px;
			}
		}
	}
	&__radio {
		&--item {
			label {
				padding: 5px;
				> input[type='radio'] {
					-moz-appearance: none;
					-webkit-appearance: none;
					appearance: none; /* in case this ever gets supported */
					background: #fff;
					border-radius: 100%;
					border: 1px solid transparent;
					display: inline-block;
					cursor: pointer;
					text-align: center;
					top: 5px;
					width: 20px;
					height: 20px;
					margin-right: 10px;
					vertical-align: middle;
					transition: all 250ms ease;
					&:focus {
						border-color: transparent;
						outline: none;
					}

					&:checked {
						border-color: #fff;
						transition: all 250ms ease;

						&:after {
							padding-left: 1px;
							padding-top: 3px;
							@include rounded(100%);
						}
					}
				}
			}
		}
		&--list {
			list-style: none;
		}
	}
}

.action-bar {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	width: 100%;

	&-status {
		display: inline-block;
	}

	&--questions {
		@include font-source(14px, $gray-800, $font-weight-semibold);
		p {
			margin-bottom: 0;
		}

		.sla {
			width: max-content;
			display: inline-block;
			margin-right: 24px;
		}
	}

	&-actions {
		display: flex;
		justify-content: flex-end;
		flex: 1 0 0;
		margin-left: auto;
		align-items: center;

		& > * {
			margin-right: 6px;
		}
	}

	&-actions > button {
		margin-right: 8px;
	}

	&-actions > button:last-child {
		margin-right: 0px;
	}
}

.amendment-count {
	@include font-source(14px, $gray-800, $font-weight-semibold);
}

.userOption {
	> div:nth-of-type(2) {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		span {
			@include font-source(14px, $gray-600, $font-weight-semibold);
		}
	}
}

.wordCount {
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	span {
		@include font-source(14px, $gray-600, $font-weight-semibold);
	}
}

.datasetName {
	@include font-source(14px, $gray-800, $font-weight-semibold);
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.datasetDescription {
	@include font-source(14px, $gray-600, $font-weight-semibold);
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.pad-1 {
	padding-right: 1px !important;
}

.dar-form {
	h3 {
		padding: 0 25px;
		white-space: pre-line;
		@include font-source(14px, $gray-dark, $font-weight-semibold);
		line-height: 21px;
		white-space: pre-line;
	}

	h4 {
		margin-bottom: 0;
		padding: 16px 25px;
		@include font-source(17px, $gray-dark, $font-weight-bold);
	}

	.form-control {
		margin-bottom: 8px;
	}

	.form-group-nested {
		border-left: 4px solid $gray-400;
	}

	.form-group {
		position: relative;
		margin-bottom: 2px;
		transition: all 0.2s ease-in-out;
		box-sizing: border-box;

		label {
			@include font-source(14px, $gray-800, $font-weight-semibold);
			margin-bottom: 5px;
			width: 90%;
			white-space: pre-line;
		}

		p {
			@include font-source(13px, $gray-700, $font-weight-semibold);
			margin-bottom: 5px;
			width: 90%;
		}
	}
}

.card .activeCard {
	border-left: 4px solid $gray-400 !important;
}

.activeCard .button-primary {
	background-color: #475da7;
	color: $white;
}

.card {
	border: 0 !important;
	border-left: 4px solid #fff !important;
	border-radius: 0 !important;
	background-color: $white !important;
	border-bottom: 1px solid $background !important;
}

.card-header {
	border-radius: 0 !important;
	background-color: $white !important;
	border: 0 !important;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	padding: 16px 32px 16px 20px !important;
	transition: linear padding 250ms;
	cursor: pointer;
}

.disabled {
	pointer-events: none;
	opacity: 0.5;
}

.card-header.active {
	padding-bottom: 1px !important;
	opacity: 1 !important;
}

.card-body {
	padding: 8px 32px 32px 64px !important;
}

.stepNumber {
	width: 32px;
	height: 32px;
	border: solid 2px $gray-400;
	border-radius: 100%;
	display: flex;
	flex-flow: column nowrap !important;
	justify-content: center;
	align-items: center;
	margin-right: 12px;
}

.stepNumber.active {
	border: solid 2px $teal;
}

.stepNumber.completed {
	background-color: $teal;
	border: solid 2px $teal;
}

#messageLink,
#howToRequestAccessLink,
#approvedResearcherLink,
#infoGovernanceLink,
#dsptLink {
	color: $purple !important;
	text-decoration: underline !important;
	display: inline-block !important;
}

.panConfirm {
	text-align: right;
	margin-top: 16px;
}

.panConfirm > button {
	height: 40px;
	width: 85px;
}

.action-control {
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: center;
	padding: 8px 0px;
	position: absolute;
	top: -8px;
	right: 25px;
	cursor: pointer;
	min-width: 32px;
	height: 32px;
	transition: visibility 0.55s linear, opacity 0.35s ease-in-out;

	& > i {
		padding: 0 5px;
	}
}

.form-group .addFormInputTypeAhead {
	height: min-content !important;
	min-height: 40px;
}

.question-wrap {
	position: relative;
	margin-bottom: 0;
	padding: 3px 25px 3px 25px;

	&:hover {
		background: #f6f7f8;
		> .action-control {
			background: $white;
			box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.09), -3px -3px 4px 0 rgba(0, 0, 0, 0.03);
			> .toolTipHidden {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}

.addFormInputTypeAhead .dropdown-item.active,
.addFormInputTypeAhead .dropdown-item:active {
	background-color: $gray-light;
}

.dar-form-check-label {
	padding-left: 8px;
	margin-bottom: 0;
}

.dar-form-check-group {
	display: flex;
	flex-flow: row;
	align-items: center;
	height: 24px;
}

.dar-form-check-group > button {
	margin-right: 32px;
}

.dar-form-check {
	height: 24px;
	width: 24px;
}

.checkSvg {
	fill: #ffffff !important;
	width: 100px;
}

input[type='checkbox']:after {
	width: 24px;
	height: 24px;
	border: solid 2px #d6d6d6;
	background-color: $white;
	content: '';
	display: inline-block;
	visibility: visible;
	border: solid 2px #d6d6d6;
}

.dar-form-check-group input[type='checkbox']:checked:after {
	width: 24px;
	height: 24px;
	border: solid 2px #d6d6d6;
	background-color: $white;
	position: relative;
	background-image: url(../../images/tick.svg);
	background-repeat: no-repeat;
	background-size: 21px 21px;
	display: inline-block;
	visibility: visible;
	border: solid 2px #d6d6d6;
	content: '';
}

.makeADecisionDropdown {
	position: relative;
	left: -87px !important;
	width: 320px !important;
	max-height: 314px !important;
	box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.09), -3px -3px 4px 0 rgba(0, 0, 0, 0.03) !important;
	background-color: $white !important;
	padding: 12px 16px 12px 16px !important;

	.option {
		padding: 4px 0;
	}
}

.makeADecisionHeader {
	position: sticky;
	top: 0;
	background-color: $white;
	z-index: 1;
	padding-left: 16px;
	padding-right: 16px;
}

.review-phase {
	margin-bottom: 20px;
	position: relative;

	.option {
		padding: 4px 0;
	}

	&:after {
		position: absolute;
		bottom: -8px;
		left: -15px;
		height: 1px;
		background: $gray-pale;
		width: 316px;
		z-index: 1000;
		content: '';
	}
}

.listOfVersionsButton {
	display: inline-block !important;
	padding: 2px 10px 3px 10px;
	border-radius: 16px !important;
	font-family: museo-sans-rounded !important;
	font-size: 14px !important;
	line-height: 20px !important;
	font-weight: 300 !important;
	margin-right: 4px !important;
	margin-bottom: 4px !important;
	background-color: $white;
	color: $purple !important;
	min-width: 100px;
}

.listOfVersionsButton:focus {
	box-shadow: none !important;
}

.listOfVersionsButton::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: '';
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-left: 0.3em solid transparent;
}

.listOfVersionsDropdown {
	width: 200px !important;
	max-height: 176px !important;
	box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.09), -3px -3px 4px 0 rgba(0, 0, 0, 0.03) !important;
	background-color: $white !important;
	overflow-x: hidden !important;
	overflow-y: scroll !important;
	margin-bottom: 24px !important;
	padding-top: 12px !important;
	padding-bottom: 12px !important;
	top: 10px !important;
	left: 0px !important;
}

.deleteDraftDataset {
	text-decoration: underline;
	margin-right: 24px;
}
